import React, { useState, useEffect } from 'react';

const SkillsetLevel3 = ({ skillset3, qid, epiccData }) => {
  const [selectedValue, setSelectedValue] = useState(epiccData[qid] || '0');

  useEffect(() => {
    setSelectedValue(epiccData[qid] || '0');
  }, [epiccData, qid]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  return (
    <tr className="level3">
      <td>{skillset3.text}</td>
      <td>
        <select
          id={qid}
          className="skillScore"
          value={selectedValue}
          onChange={handleSelectChange}
        >
          <option value="0">Select</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </td>
    </tr>
  );
};

export default SkillsetLevel3;
