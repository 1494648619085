import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import EpicForm from '../Components/epic.component';
import Home from '../Components/home.component';

const Main = () => {
  const [activeComponent, setActiveComponent] = useState('home');
  const [assessmentYear, setAssessmentYear] = useState(1);
  const [assessmentTerm, setAssessmentTerm] = useState(1);
  const uid = 5678;

  const changeMainParams = (params) => {
    setActiveComponent(params.activeComponent);
    setAssessmentYear(params.assessmentYear);
    setAssessmentTerm(params.assessmentTerm);
  };

  return (
    <>
      {activeComponent === 'epic' ? (
        <EpicForm
          uid={uid}
          changeMainParams={changeMainParams}
          assessmentYear={assessmentYear}
          assessmentTerm={assessmentTerm}
        />
      ) : (
        <Home uid={uid} changeMainParams={changeMainParams} />
      )}
    </>
  );
};

export default Main;
