import React, { useState, useEffect } from 'react';
import SkillsetLevel3 from './skillset-level3';

const SkillsetLevel2 = ({ skillset2, index, qid, epiccData }) => {
  const skillsLevel3 = skillset2.subs || [];
  const level2Classname = skillsLevel3.length > 0 ? 'level2' : 'level2_lite';
  const [selectedValue, setSelectedValue] = useState(epiccData[qid] || '0');

  useEffect(() => {
    setSelectedValue(epiccData[qid] || '0');
  }, [epiccData, qid]);

  // Handle the select change
  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    // You can perform additional actions or state updates here if needed.
  };

  return (
    <>
      <tr className={level2Classname}>
        <td>{skillset2.text}</td>
        <td>
          <select
            id={qid}
            className="skillScore"
            value={selectedValue}
            onChange={handleSelectChange}
          >
            <option value="0">Select</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </td>
      </tr>
      {skillsLevel3.map((skillset3, index) => (
        <SkillsetLevel3
          qid={`${qid}_${index}`}
          skillset3={skillset3}
          epiccData={epiccData}
          i={index}
          key={index}
        />
      ))}
    </>
  );
};

export default SkillsetLevel2;
