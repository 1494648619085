import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const Home = ({ uid, changeMainParams }) => {
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(1);
  const [term, setTerm] = useState(1);
  const [student, setStudent] = useState({ name: '', class: '', age: '' });

  useEffect(() => {
    getStudentDetails();
  }, []);

  const getStudentDetails = () => {
    setLoading(true);

    const extApiUrl = `${process.env.REACT_APP_EXT_API_ROOT}get-student-details`;
    const details = {
      student_id: uid,
    };

    const formBody = Object.keys(details)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
      .join('&');

    fetch(extApiUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((data) => {
        const receivedStudent = data || {};
        setStudent(receivedStudent);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const setMainParams = () => {
    changeMainParams({
      assessmentYear: year,
      assessmentTerm: term,
      activeComponent: 'epic',
    });
  };

  return (
    <div className="form-wrapper">
      <div className="d-flex justify-content-center">
        <div className="page-title">Evaluation Programme for Intellectually Challenged Children</div>
      </div>
      <div className="d-flex flex-column justify-content-left homeStudentDetails">
        <div className="">പേര് : {student.name}</div>
        {/* <div className="">ക്ലാസ് : {student.class}</div>
        <div className="">വയസ്സ് : {student.age}</div> */}
        <div className="">
          വർഷം :{' '}
          <select id="idAssessmentYear" onChange={(e) => setYear(e.target.value)} value={year}>
            <option value="1">ഒന്നാം വർഷം</option>
            <option value="2">രണ്ടാം വർഷം</option>
            <option value="3">മൂന്നാം വർഷം</option>
            <option value="4">നാലാം വർഷം</option>
            <option value="5">അഞ്ചാം വർഷം</option>
          </select>
        </div>
        <div className="">
          ടേം :{' '}
          <select id="idAssessmentTerm" onChange={(e) => setTerm(e.target.value)} value={term}>
            <option value="1">I</option>
            <option value="2">II</option>
            <option value="3">III</option>
          </select>
        </div>
      </div>
      <div className="homeStudentDetails-footer">
        <Button onClick={setMainParams} variant="success" size="sm" block="block" type="button" className="mt-4">
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default Home;
