// Import React
import React from "react";

// Import Bootstrap
import { Nav, Navbar, Container, Row, Col }
		from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.css";

// Import Custom CSS
import "./App.css";

// Import from react-router-dom
import { BrowserRouter as Router, Switch,
	Route, Link } from "react-router-dom";

// Import other React Component
import EpicForm from
	"./Components/epic.component";
import Main from
	"./Components/main.component";

// App Component
const App = () => {
return (
	<Router>
	<div className="App">
		<header className="App-header">
		<Navbar>
			<Container>
			<Navbar.Brand></Navbar.Brand>

			{/*<Nav className="justify-content-end">
				<Nav>
					<Link to={"/"}
						className="nav-link">
						Home
					</Link>
				</Nav>
				<Nav>
					<Link to={"/form"}
						className="nav-link">
						Epicc
					</Link>
				</Nav>
			</Nav>*/}
			</Container>
		</Navbar>
		</header>

		<Container>
		<Row>
			<Col md={12}>
			<div className="wrapper">
				<Main thisComponent='home' />
				{/*<Switch>
				<Route exact path="/"
					component={Home} />
				<Route exact path="/form"
					component={EpicForm} />
				<Route path="/create-student"
					component={CreateStudent} />
				<Route path="/edit-student/:id"
					component={EditStudent} />
				<Route path="/student-list"
					component={StudentList} />
				</Switch>*/}
			</div>
			</Col>
		</Row>
		</Container>
	</div>
	</Router>
);
};

export default App;
