import React, { useState, useEffect } from 'react';
import SkillsetLevel2 from './skillset-level2';

const SkillsetLevel1 = ({ skillset, index, qid, epiccData }) => {
  const skillsLevel2 = skillset.subs || [];
  const [selectedValue, setSelectedValue] = useState(epiccData[qid] || '0');

  useEffect(() => {
    setSelectedValue(epiccData[qid] || '0');
  }, [epiccData, qid]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  return (
    <>
      <tr className="level1">
        <td>{skillset.text}</td>
        <td>
          <select
            id={qid}
            className="skillScore"
            value={selectedValue}
            onChange={handleSelectChange}
          >
            <option value="0">Select</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </td>
      </tr>
      {skillsLevel2.map((skillset2, index) => (
        <SkillsetLevel2
          qid={`${qid}_${index}`}
          skillset2={skillset2}
          epiccData={epiccData}
          i={index}
          key={index}
        />
      ))}
    </>
  );
};

export default SkillsetLevel1;
