import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import SkillsetLevel1 from './skillset-level1';

const Epic = ({ uid, assessmentYear, assessmentTerm, changeMainParams }) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [student, setStudent] = useState({ name: '', class: '' });
  const [epiccData, setEpiccData] = useState({});
  const [term, setTerm] = useState(assessmentTerm);
  const assessmentYears = {
    '1': 'ഒന്നാം വർഷം',
    '2': 'രണ്ടാം വർഷം',
    '3': 'മൂന്നാം വർഷം',
    '4': 'നാലാം വർഷം',
    '5': 'അഞ്ചാം വർഷം',
  };

  useEffect(() => {
    getStudentDetails(uid);
    getQuestions(uid);
  }, [uid, assessmentYear, term]);

  useEffect(() => {
    getStudentEpicc();
  }, [term]);

  const getStudentDetails = (uid) => {
    setLoading(true);

    const extApiUrl = `${process.env.REACT_APP_EXT_API_ROOT}get-student-details`;
    const details = {
      student_id: uid,
      year: assessmentYear,
    };

										 
																					 
				 

    fetch(extApiUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(details).toString(),
    })
      .then((response) => response.json())
      .then((studentData) => {
        setStudent(studentData || {});
						  
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const getQuestions = (uid) => {
					 

    const extApiUrl = `${process.env.REACT_APP_EXT_API_ROOT}get-epic-questions`;
    const details = {
      student_id: uid,
      year: assessmentYear,
    };

										 
																					 
				 

    fetch(extApiUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(details).toString(),
    })
      .then((response) => response.json())
      .then((questionsData) => setQuestions(questionsData || []))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const getStudentEpicc = () => {
    const query_data = { student_id: uid, assessmentYear, assessmentTerm:term };
    axios.post(`${process.env.REACT_APP_BACKEND_ROOT}api/epicc/get-one`, query_data)
      .then((res) => {console.log(res);
        if (res.data) { 
          setEpiccData(res.data.epicc_data);
        }
      })
      .catch((error) => console.log(error));
  };

  const onSubmit = () => {
    const skillScoresObject = {};
    const skillScores = document.getElementsByClassName('skillScore');
    for (let i = 0; i < skillScores.length; i++) {
      if (skillScores[i].value > 0) {
        skillScoresObject[skillScores[i].id] = skillScores[i].value;
      }
    }
    const epicc_data = {
      student_id: uid,
      assessmentYear,
      assessmentTerm: term,
      epicc_data: skillScoresObject,
    };

    axios.post(`${process.env.REACT_APP_BACKEND_ROOT}api/epicc/save`, epicc_data)
      .then((res) => console.log(res.data));
  };

  const setMainParams = () => {
    changeMainParams({ activeComponent: 'home' });
  };

  const skillSets = questions.skills || [];
  const yearText = assessmentYears[assessmentYear] || 'ഒന്നാം വർഷം';

  return (
    <>
      <Button onClick={setMainParams} variant="danger" size="sm" block type="button" className="mt-4">
        Back
      </Button>
      <div className="form-wrapper">
        <div className="d-flex justify-content-center">
          <div className="page-title">Evaluation Programme for Intellectually Challenged Children</div>
        </div>
        <div className="studentDetailsWrapper">
          <div className="d-flex justify-content-between studentDetails">
            <div className="">പേര്: {student.name}</div>
            {/* <div className="">ക്ലാസ്: {student.class}</div> */}
          </div>
          <div className="d-flex justify-content-between studentDetails">
            <div className="">വർഷം : {yearText}</div>
            <div className="">
              ടേം:{' '}
              <select defaultValue={assessmentTerm} onChange={(event) => setTerm(event.target.value)}>
                <option value="1">I</option>
                <option value="2">II</option>
                <option value="3">III</option>
              </select>
            </div>
          </div>
        </div>
        <Form className="epicForm">
          <table>
            <thead>
              <tr>
                <th className="skillsName">നൈപുണികൾ</th>
                <th className="skillsValue">നില നിർണ്ണയം<br />Current Level Assessment</th>
              </tr>
            </thead>
            <tbody>
              {skillSets.map((skillset, index) => (
                <SkillsetLevel1
                  epiccData={epiccData}
                  skillset={skillset}
                  qid={`year_${assessmentYear}_${term}_${index}`}
						   
                  key={index}
                />
              ))}
            </tbody>
          </table>
          <Button onClick={onSubmit} variant="danger" size="sm" block type="button" className="mt-4">
            Save
          </Button>
        </Form>
      </div>
    </>
  );
};

export default Epic;